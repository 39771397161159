import React from "react";
import { v4 as uuidv4 } from "uuid";
import TableRow from "./adminTableRow";

interface TableComponentProps {
  items: any;
  approveHandler: any;
  rejectHandler: any;
  deleteHandler: any;
  updateHandler: any;
  ddCategory: string;
}

export default function TableComponent(props: TableComponentProps): any {
  const {
    items,
    approveHandler,
    rejectHandler,
    deleteHandler,
    updateHandler,
    ddCategory,
  } = props;

  const thClasses =
    "p-2 border-b border-blue text-left text-xs font-bold text-gray-600 admin-table-header";

  return items && items.length > 0 ? (
    <div className="overflow-x-auto-xx">
      <table className="min-w-full table-fixed admin-table text-xs">
        <thead>
          <tr>
            <th className={thClasses}>Label</th>
            <th className={thClasses}>Definition</th>
            {/* <th className={thClasses}>DD ID</th> */}
            <th className={thClasses}>Parent</th>
            <th className={thClasses}>Status</th>
            <th className={thClasses}>Created at</th>
            <th className={thClasses}>Material ID</th>
            <th className={thClasses}>Actions </th>
          </tr>
        </thead>
        <tbody>
          {items.map((item: any) => {
            return (
              <TableRow
                key={uuidv4()}
                item={item}
                approveHandler={approveHandler}
                rejectHandler={rejectHandler}
                deleteHandler={deleteHandler}
                updateHandler={updateHandler}
                ddCategory={ddCategory}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  ) : (
    "Please select a category first."
  );
}
