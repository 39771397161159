/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useContext } from "react";
import { navigate } from "gatsby";
import Select from "react-select";
import { jwtDecode } from "jwt-decode";
import Layout from "../../../components/layout";

import { AuthenticationState } from "../../../context/AuthenticationContext";
import { DictionaryDataState } from "../../../context/DictionaryDataContext";
import { validateToken } from "../../../lib/functions";
import H1 from "../../../components/htmlElements/h1";

import LoadingIcon from "../../../components/loadingIcon";
import getApiData from "../../../lib/getApiData";
import TableComponent from "../../../components/adminElements/adminTableComponent";

function selectOptionsStringToArray(str: string | string[]): any[] {
  if (typeof str === "object") {
    return str.map((item: any) => {
      return selectOptionsStringToArray(item as string);
    });
  }
  return { label: str.split("/").pop(), value: str };
}

/**
 * TO IMPORVE: Load `endpoints` from a data file to be reused elsewhere
 */
const endpoints: any = [
  {
    endpoint: "dd/items/typeOfInstruments",
    label: "Type of Material",
  },
  {
    endpoint: "dd/items/researchAreas",
    label: "General Research Areas",
  },
  {
    endpoint: "dd/items/participantTypes",
    label: "Participant type(s)",
  },
  {
    endpoint: "dd/items/proficiencies",
    label: "Proficiency of learners/users",
  },
  {
    endpoint: "dd/items/journals",
    label: "Name of Journal",
  },
  {
    endpoint: "dd/items/publishers",
    label: "Publisher",
  },
  {
    endpoint: "dd/items/oasisSummaryCollectionName",
    label: "Collection names",
  },
];

export default function DictionaryDataManagerPage() {
  const authenticationState = useContext(AuthenticationState) || {
    token: "",
    isAuthenticated: false,
  };

  const dictionaryDataState = useContext(DictionaryDataState) || "";

  const { token, isAuthenticated } = authenticationState;

  const defaultFilterBy = "New";

  // eslint-disable-next-line prettier/prettier
  const [filterCategory, setFilterCategory]: any = useState("");
  const [filterBy, setFilterBy]: any = useState(defaultFilterBy);
  const [items, setItems]: any = useState([]);
  const [loading, setLoading] = useState(true);

  function getDdCategory() {
    const parts = filterCategory.split("/");
    return parts[parts.length - 1];
  }

  /**
   * Check user token each step to make sure he has a valid token
   */
  useEffect(() => {
    (async () => {
      if (token && isAuthenticated) {
        await validateToken(token);

        // Decode the token
        const decodeToken = jwtDecode(authenticationState.token);
        if (decodeToken["custom:userRole"] !== "admin") {
          navigate("/");
        }

        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (filterCategory !== "" && filterBy !== "") {
        const ddCategory = getDdCategory();
        console.log("🚀 ~ ddCategory:", ddCategory)

        const ddItems: any = dictionaryDataState[ddCategory];
        console.log("🚀 ~ ddItems:", ddItems)

        if (ddItems && filterBy && filterBy !== "All") {
          const filteredItems = ddItems.filter(
            (item: any) => item.status === filterBy.toUpperCase()
          );
          setItems(filteredItems);
        } else {
          setItems(ddItems);
        }
      }
    })();
  }, [filterCategory, filterBy]);

  /**
   * HANDLERS
   *
   * Now we specify all our handlers: approve, reject, update and delete
   *
   */

  type ApiParams = {
    endpoint: string;
    method: string;
    params: any;
    headers: {
      "X-Amz-Security-Token": string;
    };
    debug: boolean;
  };

  type ButtonResponseHandler = (response: {
    status: number;
    data: { category: string; id: string };
  }) => void;

  const apiHandler = async (
    item: any,
    method: string,
    callback: ButtonResponseHandler
  ) => {
    const ddCategory = getDdCategory();

    const params: ApiParams = {
      endpoint: `dd/item/${ddCategory}/${item.id}`,
      method,
      params: item,
      headers: {
        "X-Amz-Security-Token": authenticationState.token,
      },
      debug: false,
    };

    try {
      const response = await getApiData(params);
      console.log(`🚀 ~ ${method} Response:`, response);

      // Uncomment this is you are running some tests and don't want to submit data
      // const response = {
      //   status: 200,
      //   data: {
      //     category: ddCategory,
      //     id: item.id
      //   }
      // }

      if (response.status === 200) {
        callback({
          status: 200,
          data: {
            category: ddCategory,
            id: item.id,
          },
        });
      } else {
        console.error(
          `Failed to update/delete item. Status: ${response.status}`
        );
      }
    } catch (error) {
      console.error(`Error occurred during ${params.method}:`, error);
    }
  };

  const approveHandler = (
    item: any,
    approveButtonResponseHandler: ButtonResponseHandler
  ) => {
    const updatedItem = { ...item, status: "APPROVED" };
    apiHandler(updatedItem, "put", approveButtonResponseHandler);
  };

  const rejectHandler = (
    item: any,
    rejectButtonResponseHandler: ButtonResponseHandler
  ) => {
    const updatedItem = { ...item, status: "REJECTED" };
    apiHandler(updatedItem, "put", rejectButtonResponseHandler);
  };

  const deleteHandler = (
    item: any,
    deleteButtonResponseHandler: ButtonResponseHandler
  ) => {
    apiHandler(item, "delete", deleteButtonResponseHandler);
  };

  const updateHandler = (
    item: any,
    updateButtonResponseHandler: ButtonResponseHandler
  ) => {
    apiHandler(item, "put", updateButtonResponseHandler);
  };

  return (
    <Layout>
      {token && isAuthenticated && !loading ? (
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-12 md:col-span-12">
            <H1
              innerContent="Dictionary Data Manager"
              additionalClasses="pb-5"
            />
          </div>

          <div className="col-span-8 md:col-span-8 filterCategory">
            Category:
            <Select
              name="filterCategory"
              placeholder="Please select a category to manage"
              options={endpoints.map((endpoint: any) => {
                return {
                  value: endpoint.endpoint,
                  label: endpoint.label,
                };
              })}
              onChange={(selectedOption: any) => {
                setFilterCategory(selectedOption.value);
              }}
            />
          </div>

          <div className="col-span-4 md:col-span-4">
            Status:
            <Select
              name="filterBy"
              placeholder="Select what you want to see:"
              options={["All", "New", "Approved", "Rejected"].map((item) => {
                return { label: item, value: item };
              })}
              onChange={(selectedOption: any) => {
                setFilterBy(selectedOption.value);
              }}
              value={selectOptionsStringToArray(filterBy)}
            />
          </div>

          <div className="col-span-12 text-sm">
            <strong>Action clarification:</strong>
            <ul>
              <li>
                <strong>Approve</strong> - This will approve a dictionary item
                and it will become available for all uploaders to assign to
                their material and to those searching IRIS.
              </li>
              <li>
                <strong>Reject</strong> - This option will only allow this
                dictionary item to be attached to this specific material (it
                will not be available in the search filters on the left). Only
                someone searching for this specific term would be able to find
                the specific material it is attached to.
              </li>
              {/* <li><strong>Delete</strong> - This option will delete the dictionary item from the list and from the material. This cannot be undone.</li> */}
            </ul>
          </div>

          <div className="col-span-12 md:col-span-12">
            <div className="itemsDisplay pt-5">
              <TableComponent
                items={items}
                approveHandler={approveHandler}
                rejectHandler={rejectHandler}
                deleteHandler={deleteHandler}
                updateHandler={updateHandler}
                ddCategory={getDdCategory()}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="text-center">
          <LoadingIcon />
        </div>
      )}
    </Layout>
  );
}
