import React, { useContext, useState } from "react";
import clsx from "clsx";
import { isArray } from "lodash";
import Button from "../htmlElements/button";
import AdminInputField from "./adminInputField";
import { formatDate } from "../../lib/functions";
import LoadingIcon from "../loadingIcon";

const createMaterialLink = (materialId: string) => {
  return (
    <a
      href={`/details/${materialId}`}
      target="_blank"
      rel="noreferrer"
      className="float-left mr-1"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 24 24"
      >
        <path d="M19 19h-14v-14h7v-2h-7c-1.104 0-2 .896-2 2v14c0 1.104.896 2 2 2h14c1.104 0 2-.896 2-2v-7h-2v7zm-4-18v2h4.586l-11.293 11.293 1.414 1.414 11.293-11.293v4.586h2v-9h-9z" />
      </svg>
    </a>
  );
};

interface TableRowProps {
  item: any;
  approveHandler: any;
  rejectHandler: any;
  deleteHandler: any;
  updateHandler: any;
  ddCategory: string;
}

export default function TableRow(props: TableRowProps): any {
  // Props
  const {
    item: mainItem,
    approveHandler,
    rejectHandler,
    deleteHandler,
    updateHandler,
    ddCategory,
  } = props;

  // States
  const [statusText, setStatusText] = useState(mainItem.status);
  const [loading, setLoading] = useState(false);
  const [trClasses, setTrClasses] = useState("");
  const [item, setItem] = useState(mainItem);

  // Classes
  const tdClasses =
    "p-2 whitespace-no-wrap border-b border-gray whitespace-nowrap";

  /**
   * HANDLERS
   */

  const approveButtonResponseHandler = (response: any) => {
    if (response.status === 200) {
      setStatusText("APPROVED");
    } else {
      alert(
        "Sorry, an error occured. Please contact the admin for further help."
      );
    }
    setLoading(false);
  };

  const rejectButtonResponseHandler = (response: any) => {
    if (response.status === 200) {
      setStatusText("REJECTED");
    } else {
      alert(
        "Sorry, an error occured. Please contact the admin for further help."
      );
    }
    setLoading(false);
  };

  const deleteButtonResponseHandler = (response: any) => {
    if (response.status === 200) {
      setTrClasses("hidden");
    } else {
      alert(
        "Sorry, an error occured. Please contact the admin for further help."
      );
    }
    setLoading(false);
  };

  const approveButtonHandler = () => {
    setLoading(true);
    approveHandler(item, approveButtonResponseHandler);
  };

  const rejectButtonHandler = () => {
    setLoading(true);
    rejectHandler(item, rejectButtonResponseHandler);
  };

  const deleteButtonHandler = () => {
    setLoading(true);
    deleteHandler(item, deleteButtonResponseHandler);
  };

  // We need to update `item` when we make changes.
  const itemUpdateHandler = (updatedItem: any) => {
    setItem(updatedItem);
  };

  const buttons = (
    <>
      {statusText === "NEW" ? (
        <Button
          innerContent="Approve"
          additionalClasses="approve-button mr-2"
          color="blue"
          textSize="xs"
          onClick={() => {
            if (
              confirm("This action cannot be undone! Do you want to continue?")
            ) {
              approveButtonHandler();
            }
          }}
        />
      ) : null}
      {statusText === "NEW" ? (
        <Button
          innerContent="Reject"
          additionalClasses="approve-button mr-2"
          color="blue"
          textSize="xs"
          onClick={() => {
            if (
              confirm("This action cannot be undone! Do you want to continue?")
            ) {
              rejectButtonHandler();
            }
          }}
        />
      ) : null}
      {/* { statusText === "NEW" || statusText === "REJECTED" ? (
          <button className="delete-button" onClick={() => {
            if (
              confirm("This action cannot be undone! Do you want to continue?")
            ) {
              deleteButtonHandler();
            }
          }}>delete</button>
        ) : null } */}
    </>
  );

  // Create the `createdAt` date
  let createdAt = "Not available";
  try {
    if (item.createdAt && item.createdAt !== undefined) {
      createdAt = formatDate(item.createdAt);
    }
  } catch (error) {
    console.log("error: ", error);
  }

  // Create the material link if valid
  let materialLinks: any = "";
  try {
    if (item.materialId && item.materialId !== undefined) {
      if (isArray(item.materialId)) {
        materialLinks = item.materialId.map((item: any) => {
          return createMaterialLink(item);
        });
      } else {
        materialLinks = createMaterialLink(item.materialId);
      }
    }
  } catch (error) {
    console.log("error: ", error);
  }

  return (
    <tr key={item.id} className={clsx("admin-table-row text-xs", trClasses)}>
      <td className={tdClasses}>
        <AdminInputField
          updateHandler={updateHandler}
          itemUpdateHandler={itemUpdateHandler}
          item={item}
          ddCategory={ddCategory}
          field="label"
        />
      </td>
      <td className={tdClasses}>
        <AdminInputField
          updateHandler={updateHandler}
          itemUpdateHandler={itemUpdateHandler}
          item={item}
          ddCategory={ddCategory}
          field="def"
        />
      </td>
      {/* <td className={tdClasses}>{item.value}</td> */}
      <td className={tdClasses}>
        <AdminInputField
          updateHandler={updateHandler}
          itemUpdateHandler={itemUpdateHandler}
          item={item}
          ddCategory={ddCategory}
          field="parent"
        />
      </td>
      <td className={tdClasses}>{statusText}</td>
      <td className={tdClasses}>{createdAt}</td>
      <td className={tdClasses}>{materialLinks}</td>
      <td className={tdClasses}>{loading ? <LoadingIcon /> : buttons}</td>
    </tr>
  );
}
